import { Portal } from "@reach/portal";
import { useTooltip, TooltipPopup } from "@reach/tooltip";
import React from "react";

type Props = {
	label: React.ReactNode;
	ariaLabel?: string;
	children: JSX.Element;
};

export default function TooltipComponent({
	label,
	ariaLabel = "",
	children,
}: Props) {
	const [trigger, tooltip] = useTooltip();
	const { isVisible, triggerRect } = tooltip;
	return (
		<>
			{React.cloneElement(children, trigger)}
			{isVisible && (
				<Portal>
					<div
						style={{
							position: "absolute",
							left: triggerRect
								? triggerRect.left - 6 + triggerRect.width / 2
								: 0,
							// 2px is magic number for this size of arrow
							top: triggerRect
								? triggerRect.bottom + window.scrollY + 2
								: 0,
							width: 0,
							height: 0,
							borderLeft: "6px solid transparent",
							borderRight: "6px solid transparent",
							borderBottom: "6px solid var(--color-grey-10)",
						}}
					/>
				</Portal>
			)}
			<TooltipPopup
				{...tooltip}
				label={label}
				aria-label={ariaLabel}
				// have to inline style since className doesn't work
				style={{
					padding: 8,
					borderWidth: 0,
					borderRadius: "4px",
					backgroundColor: "var(--color-grey-10)",
					color: "var(--color-grey-100)",
					transform: "translateX(-16px)",
					zIndex: 100,
				}}
			/>
		</>
	);
}
